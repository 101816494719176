/*
-------------------------------
ROOT
-------------------------------
*/

:root {
    /*
    * COlORS
    */

    /* background colors */
    --bg-light-white: hsl(0, 0%, 100%);
    --bg-light-gray: hsl(0, 0%, 90%);
    --bg-dark-gray: rgb(170, 170, 170);

    /*
    * Text Color
    */

    --text-white: hsla(0, 0%, 100%, 1);
    --text-black: hsla(0, 0%, 0%, 1);
    --text-sunset: rgb(236, 118, 46);

    /*
    * Border color
    */

    --border-gray: rgb(204, 204, 204);

    /*
    * TYPOGRAPHY
    */

    /* font family */

    --fontFamily-open-sans: 'Open Sans', sans-serif;

    /* font size */
    --fontSize-1: 4rem;
    --fontSize-2: 3.2rem;
    --fontSize-3: 2.4rem;
    --fontSize-4: 2rem;
    --fontSize-5: 1.8rem;
    --fontSize-6: 1.6rem;
    --fontSize-7: 1.4rem;

    /* font weight */
    --weight-regular: 400;
    --weight-medium: 500;
    --weight-semiBold: 600;
    --weight-bold: 700;

    /* spacing */

    --section-spacing: 100px;

    /* border radius */

    --radius-24: 24px;
    --radius-40: 40px;
}

.display-7 {
    font-size: calc(1.0rem + 0.8vw);
    font-weight: 300;
    line-height: 1.2;
}


/*
-------------------------------
LOGOS / IMGS
-------------------------------
*/

.header-logo {
    padding: 10px;
    width: 200px;
    height: 40px;
}

.rectangle-10 {
    width: 100%;
    height: 49px;
    padding: 2px 8px 8px 8px;
    background: #0C5C74;
    border-color: #00000000;
    border-width: 10px;
    border-style: solid;
    border-radius: 5px 5px 5px 5px;
}

.portalBanner {
    background-size: cover;
    background-repeat: repeat;
    max-width: 100%;
    height: 168px;
}

/*
-------------------------------
EFFECTS
-------------------------------
*/

.navbar-nav>ul>li>a :hover {
    color: red;
}

a {
    all: unset;
    cursor: pointer;
}

a::hover,
a::focus {
    all: unset;
    color: inherit;
    cursor: pointer;
}

.work-link:hover a {
    color: #ec8f21;
}

.numberCircle {
    color: #ec8f21;
    background-color: rgb(245, 244, 244);
    width: 75px;
    line-height: 75px;
    border-radius: 50%;
    text-align: center;
    font-size: 32px;
}

.card-title {
    font-weight: bold;
}

.nav-link:hover {
    color: #ec8f21 !important;
}

.tableIcons {
    font-size: 1.5rem;
}


.pointer {
    cursor: pointer;
}

#loader {
    display: none;
    position: fixed;
    margin: auto;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border: 20px solid rgb(244, 148, 31);
    border-radius: 50%;
    border-top: 20px solid rgb(21, 117, 188);
    width: 150px;
    height: 150px;
    animation: spinner 4s linear infinite;
    z-index: 99999
}

@keyframes spinner {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

/*
-------------------------------
Text / Styling
-------------------------------
*/

.banner-container {
    position: relative;
    width: 100%;
    height: auto;
}

.heading-2 {
    height: 28px;
    color: #F7F6F6;
    font-family: "Open Sans";
    font-weight: bold;
    font-size: 20px;
    text-align: left;
}

/* Centered text */
.centered {
    height: 140%;
    font-size: 3rem;
    color: white;
    position: absolute;
    margin-bottom: 20px;
    left: 25%;
    transform: translate(-50%, -50%);
}

.table>thead {
    vertical-align: middle !important;
}

/* Show html content - links to hiding html in header */

html {
    visibility: visible;
    opacity: 1;
}

.main {
}

.form-check-input:checked {
    background-color: rgb(13,92,116);
    border-color: rgb(13,92,116);
}

.btn-close.custom-close {
    background-color: transparent;
    border: none;
    position: relative;
    width: 1rem;
    height: 1rem;
    cursor: pointer;
}

.btn-close.custom-close::before,
.btn-close.custom-close::after {
    content: '';
    position: absolute;
    width: 4px; /* Adjust thickness */
    height: 1.2rem; /* Adjust height */
    background-color: #ffffff; /* White color */
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) rotate(45deg);
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.5); /* Add shadow */
}

.btn-close.custom-close::after {
    transform: translate(-50%, -50%) rotate(-45deg);
}



/*
-------------------------------
PAGINATION
-------------------------------
*/

.pagination>li>a,
.pagination>li>span {
    color: rgb(13,92,116);
}

.pagination>.active>a,
.pagination>.active>a:focus,
.pagination>.active>a:hover,
.pagination>.active>span,
.pagination>.active>span:focus,
.pagination>.active>span:hover {
    background-color: rgb(13,92,116);
    border-color: rgb(255, 255, 255);
}

.pagination {
    margin-left: 0px !important;
}

/*
-------------------------------
Signature
-------------------------------
*/

.kbw-signature {
    overflow: hidden;
    width: 60%;
    height: 150px;
    border-radius: 20px;
    background-color: white;
    display: inline-block;
    border: 1px solid rgb(13,92,116);
    -ms-touch-action: none;
}

#sig canvas {
    width: 100% !important;
    height: auto;
}

.kbw-signature-disabled {
    opacity: 0.35;
}


/*
-------------------------------
Duty Of Care Table
-------------------------------
*/

.d-none {
    display: none;
}

.fileName {
    width: 40%;
    text-align: left;
}

.issue-date {
    width: 11%;
}

.state {
    width: 11%;
}

.approved-date {
    width: 12%;
}

.view-download {
    width: 9.5%;
}

.sign {
    width: 9.5%;
}

.text-center {
    text-align: center;
}


/*
-------------------------------
MEDIA QUERIES
-------------------------------
*/

@media (min-width: 1400px) {
    .container, .container-lg, .container-md, .container-sm, .container-xl, .container-xxl {
        max-width: 1350px !important;
    }
}

@media only screen and (max-width: 600px) {
    .kbw-signature {
        width: auto !important;
    }

    .centered {
        height: 40%;
        padding-left: 20px;
    }
}

@media only screen and (max-width: 1000px) {
    .centered {
        height: 130%;
        padding-left: 30px;
    }
}

/* @media only screen and (max-width: 600px) {
  .loginBanner {
    content: url("/img/loginBannerSmall.png")
  } */
/* } */
